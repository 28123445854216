<app-intro-section></app-intro-section>
<app-ai-assistant></app-ai-assistant>
<app-pyg-x-card
  [imageFile]="'PygmaliosX'"
  [data]="card" *ngFor="let card of cards"
></app-pyg-x-card>
<app-benefits></app-benefits>
<div *ngIf="trialOpen" class="pyg-dialog-bg">
  <div class="pyg-dialog-form">
    <app-free-trial
      [place]="'Pygmalios-X page'"
      [title]="'freetrial.title' | translate"
    ></app-free-trial>
  </div>
</div>
<app-succes-dialog></app-succes-dialog>
