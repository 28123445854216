import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, Inject, Input, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgRecaptcha3Service } from 'ng-recaptcha3';
import { PygmaliosXService } from 'src/app/components/pygmalios-x/pygmalios-x.service';
import { LanguageService } from 'src/app/services/language.service';
import { environment } from 'src/environments/environment';
import { FreeTrialService } from './free-trial.service';

@Component({
  selector: 'app-free-trial',
  templateUrl: './free-trial.component.html',
  styleUrls: ['./free-trial.component.scss']
})
export class FreeTrialComponent implements OnInit, OnDestroy {

  isBrowser: boolean;
  isOpen = false;
  mailStatus:number = -1;
  unwantDomains = [
    'gmail.com', 'yahoo.com', 'hotmail.com', 'aol.com', 'outlook.com'
  ];
  unwantDomain = false;

  demoForm = new FormGroup({
    name: new FormControl('', Validators.required),
    surname: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    company: new FormControl('', Validators.required)
  });

  @Input() place:string = '';
  @Input() title:string = '';
  @Input() subtitle:string = '';

  constructor(
    private pxService: PygmaliosXService,
    private recaptcha3: NgRecaptcha3Service,
    private http:HttpClient,
    private ftService: FreeTrialService,
    @Inject(PLATFORM_ID) platformId: Object,
    public lang: LanguageService
  ) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  get name(){return this.demoForm.get('name')};
  get surname(){return this.demoForm.get('surname')};
  get email(){return this.demoForm.get('email')};
  get company(){return this.demoForm.get('company')};

  ngOnInit(): void {
    if (this.isBrowser) {
      this.recaptcha3.init(environment.recaptcha_site_key);
    }
    setTimeout(() => {
      this.isOpen = true;
    }, 300)
  }

  onCloseDialog() {
    if (this.isOpen) {
      this.pxService.trialOpen.next(false);
    }
  }

  sendRequest() {
    if (!this.demoForm.valid){
      this.demoForm.markAllAsTouched();
    };

    const domain = this.email?.value?.split('@')[1] as string;
    if (this.unwantDomains.includes(domain)) {
      this.unwantDomain = true;
      setTimeout(() => {
        this.unwantDomain = false;
      }, 6000)
      return;
    } else {
      this.unwantDomain = false;
    }

    if (this.demoForm.valid) {
      this.recaptcha3.getToken()
      .then((token:string) => {
        const headers = new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        });
        this.http.post(environment.api_free_trial,{
          name: this.name?.value,
          surname: this.surname?.value,
          email: this.email?.value,
          company: this.company?.value,
          place: this.place,
          recaptchaToken: token
        }, { headers: headers })
        .subscribe(
          data => {
            if (data.hasOwnProperty('MessageId')) {
              console.log('success', data)
              this.demoForm.reset();
              this.mailStatus = 200;
              this.onCloseDialog();
              this.ftService.successDialogIsOpened.next(true);
            } else {
              console.log('fail', data);
              this.mailStatus = 0;
              this.resetStatus();
            };
          },
          error => {
            console.log('fail', error);
            this.mailStatus = 500;
            this.resetStatus();
          }
        );
      });
    }
  }

  outsideForm () {
    this.demoForm.markAsUntouched();
  }

  resetStatus() {
    setTimeout(() => {
      this.mailStatus = -1;
    }, 5000);
  }

  ngOnDestroy() {
    this.recaptcha3.destroy();
  }
}
