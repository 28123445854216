import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  SmoothBlogNavigation,
  NavigationWidth,
  SubscribeButton,
  SmoothNavigation,
  LineEffect,
  MenuToogle
} from 'src/app/animations/navigation';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-navigate',
  templateUrl: './navigate.component.html',
  styleUrls: ['./navigate.component.scss'],
  animations: [
    SmoothNavigation,
    SmoothBlogNavigation,
    NavigationWidth,
    SubscribeButton,
    LineEffect,
    MenuToogle
  ]
})
export class NavigateComponent implements OnInit {
  menuOpen: boolean = false;
  menuImg: string = 'menu.svg';
  navChange: boolean = false;
  scrollHeigth = 65;
  productsIsActive = false;
  activeSiteSection: string = '';
  subscribeOpened = false;
  subscribeExist = false;
  public innerWidth = 0;
  language = localStorage.getItem('language') || this.langService.activeLanguage;
  languages = this.translate.langs;

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.innerWidth = window.innerWidth;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private langService: LanguageService
  ) {
    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd ) {
          this.SiteURLActiveCheck(event);
          this.language = event.url.split('/')[1];
      }
    });
   }

  private SiteURLActiveCheck(event: NavigationEnd): void {
    if (event.url.indexOf('products') !== -1) {
        this.activeSiteSection = 'products';
    } else if (event.url.indexOf('getdemo') !== -1) {
      this.activeSiteSection = 'getdemo';
    } else if (event.url.indexOf('privacy-policy') !== -1) {
      this.activeSiteSection = 'privacy-policy';
    } else if (event.url.indexOf('futureoftelco') !== -1) {
      this.activeSiteSection = 'futureoftelco';
    } else if (event.url.indexOf('blog') !== -1) {
      this.activeSiteSection = 'blog';
    } else {
        this.activeSiteSection = '';
    }

    if (this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco'){
      this.productsIsActive = true;
    } else {
      this.productsIsActive=false
    }
  }

  isSectionActive(section: string): boolean {
      return section === this.activeSiteSection;
  }

  ngOnInit(): void {
    window.addEventListener('scroll', this.scroll, true);
    this.innerWidth = window.innerWidth;
  }

  scroll = (): void => {
     if(window.scrollY >= this.scrollHeigth){
       this.navChange = true;
       let el = document.getElementById('pygNavigation');
       el?.classList.add('navbarScroll');
       el?.classList.remove('navbarTop');
       this.scrollHeigth = 20;
       if(this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco'){
        this.productsIsActive = false
       }
     }else if(window.scrollY < this.scrollHeigth){
      this.navChange = false;
      this.subscribeOpened = false;
      let el = document.getElementById('pygNavigation');
      el?.classList.add('navbarTop');
      el?.classList.remove('navbarScroll');
      this.scrollHeigth = 65;
      if((this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco') && !this.menuOpen){
        this.productsIsActive = true
      }
     }
   }

  openMenu(){
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen){
      this.menuImg = 'x.svg';
      if(this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco'){
        this.productsIsActive = false
      }
    } else {
      if(this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco'){
        this.productsIsActive = true
      }
      this.menuImg = 'menu.svg';
    }
  }


  hideMenu(){
    this.menuOpen = false;
    this.menuImg = 'menu.svg';
    if(this.activeSiteSection === 'products' || this.activeSiteSection === 'futureoftelco'){
      this.productsIsActive = true
    } else {
      this.productsIsActive = false
    }
  }

  toElement(element:string) {
    if(element === 'industry'){
      this.activeSiteSection = 'industry'
    } else if (element === 'instore'){
      this.activeSiteSection = ''
    }
    setTimeout(() => {
      document.getElementById(element)?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }, 400);
  }

  toogleSubscribeForm() {
    this.subscribeOpened = !this.subscribeOpened;
    this.focusOut();
    setTimeout(()=>{
      if(this.subscribeOpened){
        this.subscribeExist = true;
      };
    },300)
  }

  focusOut(){
    if ( this.subscribeExist ) {
      this.subscribeOpened = false;
      this.subscribeExist = false;
    }
  }

  toogleNavigationWidth():string {
    if (this.innerWidth > 1024) {
      return this.activeSiteSection === 'blog' && this.navChange ? 'blogWidth' : 'pygWidth';
    } else {
      return ''
    }
  }

  setLanguage(language: string): void {
    this.langService.setLanguage(language);
    const url = language + this.router.url.slice(3);
    this.router.navigate([url]);
  }
}
