import { Component, OnInit } from '@angular/core';
import { FreeTrialService } from '../free-trial/free-trial.service';

@Component({
  selector: 'app-succes-dialog',
  templateUrl: './succes-dialog.component.html',
  styleUrls: ['./succes-dialog.component.scss']
})
export class SuccesDialogComponent implements OnInit {

  successDialogIsOpen = false;

  constructor(private ftService:FreeTrialService) {
    this.ftService.successDialogIsOpened.subscribe(x => {
      this.successDialogIsOpen = x;
    })
  }

  ngOnInit(): void { }

  closeSuccessDialog() {
    this.ftService.successDialogIsOpened.next(false);
  }
}
