<app-vq-intro></app-vq-intro>
<app-vq-solve-problems></app-vq-solve-problems>
<app-vq-advantages></app-vq-advantages>
<app-pyg-x-card
  [imageFile]="'VirtualQueue'"
  [data]="card" *ngFor="let card of VQcards"
></app-pyg-x-card>
<app-vq-benefits></app-vq-benefits>
<div *ngIf="trialOpen" class="pyg-dialog-bg">
  <div class="pyg-dialog-form">
    <app-free-trial
      [place]="'Virtual Queue page'"
      [title]="'freetrial.title' | translate"
    ></app-free-trial>
  </div>
</div>
<app-succes-dialog></app-succes-dialog>
