<h2 class="lg:text-center pt-28 w-10/12 sm:w-9/12 mx-auto">{{ 'main_page.industries_section.title' | translate }}</h2>
<p class="w-10/12 sm:w-9/12 lg:text-center mx-auto pt-2.5 pb-11 lg:pb-9">{{ 'main_page.industries_section.subtitle' | translate }}</p>
<section class="w-10/12 sm:w-9/12 mx-auto flex flex-wrap lg:justify-between">
    <div
    class="mb-14 lg:mb-0 industry hide-on-init"
    animateOnScroll
    animationName="visible-on-scroll{{industry.id}}"
    *ngFor="let industry of industries">
        <div class="p-8 w-full">
            <img
            src="../../../../assets/img/MainPage/Industries/{{industry.industryImage}}"
            alt="{{industry.industryName | translate}}"
            class="lg:h-44 2xl:h-52 lg:mx-auto">
            <h4 class="pt-10 pb-4">{{industry.industryName | translate}}</h4>
            <p>{{industry.industryDetail | translate}}</p>
        </div>
    </div>
</section>
