<section (clickOutside)="onCloseDialog()">
  <h2>{{ title }}</h2>
  <p *ngIf="subtitle" class="pyg-subtitle">{{ subtitle }}</p>
  <p class="py-4 sm:pb-8 pyg-get-questions">{{ 'freetrial.get_questions' | translate }}</p>
  <form [formGroup]="demoForm" (clickOutside)="outsideForm()">

    <label>{{ 'freetrial.name' | translate }}</label>
    <input
      type="text"
      formControlName="name"
      [style.border]="!name?.value && name?.touched ? '2px solid red' : '2px solid #D6D2DC'"
    >
    <p
      *ngIf="!name?.value && name?.touched"
      class="pygMessage">
      {{ 'freetrial.name_warn' | translate }}
    </p>

    <label>{{ 'freetrial.lastname' | translate }}</label>
    <input
      type="text"
      formControlName="surname"
      [style.border]="!surname?.value && surname?.touched ? '2px solid red' : '2px solid #D6D2DC'"
    >
    <p
      *ngIf="!surname?.value && surname?.touched"
      class="pygMessage">
      {{ 'freetrial.lastname_warn' | translate }}
    </p>

    <label>{{ 'freetrial.email_bussines' | translate }}</label>
    <input
      type="email"
      formControlName="email"
      placeholder="@"
      [style.border]="(email?.invalid || !email?.value || unwantDomain) && email?.touched ? '2px solid red' : '2px solid #D6D2DC'"
    >
    <p
      *ngIf="(email?.invalid || !email?.value) && email?.touched"
      class="pygMessage">
        {{ 'freetrial.email_warn' | translate }}
    </p>
    <p
      *ngIf="unwantDomain"
      class="pygMessage">
        {{ 'freetrial.email_warn_bussines' | translate }}
    </p>

    <label>{{ 'freetrial.company' | translate }}</label>
    <input
      type="text"
      formControlName="company"
      [style.border]="!company?.value && company?.touched ? '2px solid red' : '2px solid #D6D2DC'"
    >
    <p
      *ngIf="!company?.value && company?.touched"
      class="pygMessage">
      {{ 'freetrial.company_warn' | translate }}
    </p>

    <div class="mt-6">
      <p class="text-red-600 text-base text-center pb-2" *ngIf="mailStatus > 204">
        {{ 'freetrial.warn' | translate }}
      </p>

      <button
        class="mx-auto z-10 font-bold rounded-l-full rounded-r-full block w-full h-12"
        (click)="sendRequest()"
      >
        {{ 'freetrial.button' | translate }}
      </button>
    </div>

    <small>{{ 'freetrial.handle_request' | translate }}
      <a [routerLink]="'/' + lang.activeLanguage + '/privacy-policy'">{{ 'freetrial.privacy_policy' | translate }}</a>
    </small>
  </form>
</section>
