import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
  activeLanguage = 'en';
  isBrowser = false;

  constructor(private translate: TranslateService, @Inject(PLATFORM_ID) platformId: Object) {
    this.isBrowser = isPlatformBrowser(platformId);

    translate.addLangs(['en', 'fr', 'nl', 'it']);
    translate.setDefaultLang('en');

    const langBrowser = this.translate.getBrowserCultureLang()?.slice(0, 2).toLowerCase();
    const langStorage = this.isBrowser ? localStorage.getItem('language') : null;

    if (langStorage && this.translate.langs.includes(langStorage)) {
      translate.use(langStorage);
      this.activeLanguage = langStorage;
    } else if (langBrowser && this.translate.langs.includes(langBrowser)) {
      translate.use(langBrowser);
      this.activeLanguage = langBrowser;
    } else {
      translate.use('en');
    }
  }

  setLanguage(lang: string) {
    if (this.isBrowser) {
      localStorage.setItem("language", lang);
    }
    this.translate.use(lang);
    this.activeLanguage = lang;
  }
}
