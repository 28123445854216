import { Component, OnInit } from '@angular/core';
import { PygmaliosXService } from '../pygmalios-x.service';

@Component({
  selector: 'app-intro-section',
  templateUrl: './intro-section.component.html',
  styleUrls: ['./intro-section.component.scss']
})
export class IntroSectionComponent implements OnInit {

  constructor(private pxService: PygmaliosXService) { }

  ngOnInit(): void {
  }

  onOpenDialog() {
    this.pxService.trialOpen.next(true);
  }
}
