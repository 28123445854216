import { OnInit, Component } from '@angular/core';

@Component({
  selector: 'app-demo-form',
  templateUrl: './demo-form.component.html',
  styleUrls: ['./demo-form.component.scss']
})
export class DemoFormComponent implements OnInit {

  demoItems = [
    'getdemo.store_revenue',
    'getdemo.pyg_platform',
    'getdemo.matching_critical',
    'getdemo.pricing_details'
  ];

  constructor() {}

  ngOnInit():void { }
}
