<section
  class="sticky top-0 w-full z-50 navbarTop pb-8"
  id="pygNavigation"
  [class.pygBgActive]="productsIsActive && !navChange"
  [@navAnime]="activeSiteSection !== 'blog' && navChange"
  [@navBlogAnime]="activeSiteSection === 'blog' && navChange"
>
  <div class="max-w-screen-2xl mx-auto">
    <div
      class="w-10/12 sm:w-9/12 mx-auto flex flex-nowrap justify-between relative"
      [@navWidthAnime]="toogleNavigationWidth()"
    >
      <img
        [src]="productsIsActive && !navChange ? '../../../assets/img/Shared/pygmalios-logo-white.svg' : '../../../assets/img/Shared/pygmalios-logo.svg'"
        class="w-40 sm:w-44 md:w-48 lg:w-52 outline-none cursor-pointer"
        [routerLink]="'/' + language + '/'"
        (click) = "toElement('instore')"
      >
      <div class="flex">
        <div class="pt-1 lg:flex lg:ml-3 xl:ml-6 hidden my-auto">
          <div
            class="cursor-pointer ml-3 xl:ml-5"
            [routerLink]="'/' + language + '/'"
            (click) = "toElement('industry')"
          >
            <p
              [class.active]="isSectionActive('industry')"
              [class.inactiveProducts]="productsIsActive"
            >
              {{ 'navigation.industries' | translate }}
            </p>
            <hr *ngIf="isSectionActive('industry') && !navChange" [@hrAnime]>
          </div>

          <div
            class="cursor-pointer ml-3 xl:ml-5"
            [routerLink]="'/' + language + '/products'"
          >
            <p
              [class.active]="isSectionActive('products')"
              [class.activeProducts]="isSectionActive('products') && !navChange"
            >
              {{ 'navigation.products' | translate }}
            </p>
            <hr
              style="background: white;"
              *ngIf="isSectionActive('products') && !navChange"
              [@hrAnime]
            >
          </div>
          <div
            class="cursor-pointer ml-3 xl:ml-5"
            [routerLink]="'/' + language + '/blog'"

          >
            <p
              [class.active]="isSectionActive('blog')"
              [class.inactiveProducts]="productsIsActive"
            >
              {{ 'navigation.blog' | translate }}
            </p>
            <hr *ngIf="isSectionActive('blog') && !navChange" [@hrAnime]>
          </div>
        </div>

        <div class="flex">
          <button
            class="font-bold rounded-l-full rounded-r-full hidden lg:block w-full h-16 lg:h-12 lg:w-40 ml-3 xl:ml-5"
            *ngIf="activeSiteSection === 'blog' && navChange"
            [@btnAnime]
            (click)="toogleSubscribeForm()"
          >
              {{ 'buttons.subscribe' | translate }}
          </button>

          <app-get-demo class="hidden lg:block ml-3 xl:ml-5"></app-get-demo>

          <div class="ml-4 pl-1 pygDropdown hidden lg:block relative my-auto">
            <div class="flex justify-between">
              <p class="active">{{ language }}</p>
              <img src="../../../assets/img/Shared/chevron.svg" alt="" class="chevron mr-3 mt-0.5">
            </div>
            <div class="pygLanguages hidden absolute bg-white -ml-1 pl-1">
              <div
                *ngFor="let lang of languages"
                class="flex justify-between cursor-pointer"
                (click)="setLanguage(lang)"
              >
                <p *ngIf="language !== lang">
                  {{ lang }}
                </p>
                <img
                  src="../../../assets/img/Flags/{{lang}}.svg"
                  alt=""
                  class="w-5 h-4 mr-3 my-auto border"
                  *ngIf="language !== lang"
                >
              </div>
            </div>
          </div>
        </div>
      </div>

      <img
        src="../../../assets/img/Shared/{{menuImg}}"
        alt="Menu"
        class="block lg:hidden cursor-pointer p-5 -mr-5"
        (click)="openMenu()"
      >

      <div
        class="pygPopup"
        (clickOutside)="focusOut()"
        *ngIf="subscribeOpened"
        [@menuAnime]
      >
        <app-popup-subscribe [place]="'blog navigation'"></app-popup-subscribe>
      </div>

    </div>
  </div>

  <app-menu>
    <div
      class="w-10/12 sm:w-9/12 mx-auto"
      (click)="hideMenu()"
      [@menuAnime]="menuOpen"
      *ngIf="menuOpen"
    >
      <div
        class="flex justify-between cursor-pointer"
        [routerLink]="'/' + language + '/'"
        (click) = "toElement('industry')"
      >
        <p
          class="p-3 my-3 -ml-2"
          [class.active]="isSectionActive('industry')"
          [class.inactiveProducts]="productsIsActive">
            {{ 'navigation.industries' | translate }}
        </p>
        <img src="../../../assets/img/Shared/arrow-right.svg" alt="arrow">
      </div>
      <hr>
      <div
        class="flex justify-between cursor-pointer"
        [routerLink]="'/' + language + '/products'"
      >
          <p
            class="p-3 my-3 -ml-2"
            [class.active]="isSectionActive('products')"
          >
              Products
          </p>
          <img src="../../../assets/img/Shared/arrow-right.svg" alt="arrow">
      </div>
      <hr>
      <div
        class="flex justify-between cursor-pointer "
        [routerLink]="'/' + language + '/blog'"
      >
          <p
            class="p-3 my-3 -ml-2"
            [class.active]="isSectionActive('blog')"
            [class.inactiveProducts]="productsIsActive"
          >
              Blog
          </p>
          <img
            style="color: white;"
            src="../../../assets/img/Shared/arrow-right.svg"
            alt="arrow"
          >
      </div>
      <hr>
      <div class="pygLanguages lg:hidden flex max-w-md -ml-2 my-3">
        <div
          *ngFor="let lang of languages"
          class="cursor-pointer mr-4"
          (click)="setLanguage(lang)"
        >
          <p
            [class.active]="language === lang">
              {{ lang }}
          </p>
          <img
            src="../../../assets/img/Flags/{{lang}}.svg"
            alt=""
            class="w-5 h-4 ml-2 border"
          >
        </div>
      </div>
    </div>
  </app-menu>
</section>
