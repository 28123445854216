import { Component } from '@angular/core';
import { PygmaliosXService } from '../pygmalios-x.service';

@Component({
  selector: 'app-benefits',
  templateUrl: './benefits.component.html',
  styleUrls: ['./benefits.component.scss']
})
export class BenefitsComponent {

  benefits = [
    'pygx.benefits.point1',
    'pygx.benefits.point2',
    'pygx.benefits.point3',
    'pygx.benefits.point4',
    'pygx.benefits.point5',
    'pygx.benefits.point6'
  ]

  constructor(private pxService: PygmaliosXService) { }

  onOpenDialog() {
    this.pxService.trialOpen.next(true);
  }
}
