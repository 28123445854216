import { Component, OnInit } from '@angular/core';
import { PygmaliosXService } from '../../pygmalios-x/pygmalios-x.service';

@Component({
  selector: 'app-vq-benefits',
  templateUrl: './vq-benefits.component.html',
  styleUrls: ['./vq-benefits.component.scss']
})
export class VqBenefitsComponent implements OnInit {

  benefits = [
    'paperless Solution',
    'better staff efficiency',
    'reduced costs',
    'increased productivity',
    'improved conversion rates',
    'informed decision-making',
    'enhanced profitability',
    'happier customers',
    'optimized staff allocation',
    'increased customer loyalty'
  ]

  constructor(private pxService: PygmaliosXService) { }

  ngOnInit(): void {
  }

  onOpenDialog() {
    this.pxService.trialOpen.next(true);
  }
}
