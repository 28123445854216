import { Component, OnInit} from '@angular/core';
import { industriesData, Industry } from './industry';

@Component({
  selector: 'app-industries',
  templateUrl: './industries.component.html',
  styleUrls: ['./industries.component.scss']
})
export class IndustriesComponent implements OnInit {
  industries:Industry[] = industriesData;

  constructor() { }

  ngOnInit() { }

}
