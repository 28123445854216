import { NgModule } from '@angular/core';
import { BrowserModule, Meta } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { NavigateComponent } from './components/navigate/navigate.component';
import { SubscribeComponent } from './components/subscribe/subscribe.component';
import { TestimonialsComponent } from './components/testimonials/testimonials.component';
import { TryDemoComponent } from './components/try-demo/try-demo.component';
import { LinksComponent } from './components/links/links.component';
import { FooterComponent } from './components/footer/footer.component';
import { MainPageComponent } from './components/main-page/main-page.component';
import { ProductsComponent } from './components/products/products.component';
import { InStoreComponent } from './components/main-page/in-store/in-store.component';
import { CompaniesComponent } from './components/main-page/companies/companies.component';
import { IndustriesComponent } from './components/main-page/industries/industries.component';
import { ExpertiseComponent } from './components/main-page/expertise/expertise.component';
import { ProductSchemaComponent } from './components/main-page/product-schema/product-schema.component';
import { ProductsTopComponent } from './components/products/products-top/products-top.component';
import { PygOfferingComponent } from './components/products/pyg-offering/pyg-offering.component';
import { GetInfoComponent } from './shared/get-info/get-info.component';
import { GetDemoComponent } from './shared/get-demo/get-demo.component';
import { ProductItemComponent } from './components/main-page/product-schema/product-item/product-item.component';
import { ProductDescriptionComponent } from './components/main-page/product-schema/product-description/product-description.component';
import { DemoFormComponent } from './components/demo-form/demo-form.component';
import { MenuComponent } from './components/navigate/menu/menu.component';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BlogComponent } from './components/blog/blog.component';
import { ArticleComponent } from './components/blog/article/article.component';
import { BlogItemComponent } from './components/blog/blog-item/blog-item.component';
import { BlogService } from './services/blog.service';
import { NgVarDirective } from './services/ngVar.directive';
import { MetaService } from './services/meta.service';
import { VideoEmbedComponent } from './components/blog/article/video-embed/video-embed.component';
import { FutureOfTelcoComponent } from './components/future-of-telco/future-of-telco.component';
import { ShareButtonsComponent } from './shared/share-buttons/share-buttons.component';
import { ShareButtonModule } from 'ngx-sharebuttons/button';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { SwiperModule } from 'swiper/angular';
import { ClickOutsideModule } from 'ng-click-outside';
import { PopupSubscribeComponent } from './components/navigate/popup-subscribe/popup-subscribe.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AnimateOnScrollModule } from 'ng2-animate-on-scroll';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { PygmaliosXComponent } from './components/pygmalios-x/pygmalios-x.component';
import { IntroSectionComponent } from './components/pygmalios-x/intro-section/intro-section.component';
import { AiAssistantComponent } from './components/pygmalios-x/ai-assistant/ai-assistant.component';
import { PygXCardComponent } from './shared/pyg-x-card/pyg-x-card.component';
import { BenefitsComponent } from './components/pygmalios-x/benefits/benefits.component';
import { FreeTrialComponent } from './shared/free-trial/free-trial.component';
import { PygmaliosXService } from './components/pygmalios-x/pygmalios-x.service';
import { FreeTrialService } from './shared/free-trial/free-trial.service';
import { SuccesDialogComponent } from './shared/succes-dialog/succes-dialog.component';
import { FrontlineTeamsComponent } from './components/main-page/frontline-teams/frontline-teams.component';
import { VirtualQueueComponent } from './components/virtual-queue/virtual-queue.component';
import { VqIntroComponent } from './components/virtual-queue/vq-intro/vq-intro.component';
import { VgSolveProblemsComponent } from './components/virtual-queue/vq-solve-problems/vq-solve-problems.component';
import { VqAdvantagesComponent } from './components/virtual-queue/vq-advantages/vq-advantages.component';
import { VqBenefitsComponent } from './components/virtual-queue/vq-benefits/vq-benefits.component';
import { VqBannerComponent } from './components/main-page/vq-banner/vq-banner.component';
import { LanguageComponent } from './lang.component';

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}

const appRoutes: Routes = [
  {
    path: ':lang',
    component: LanguageComponent,
    children: [
      {path: '', component: MainPageComponent},
      {path: 'blog/:article', component: ArticleComponent},
      {path: 'blog', component: BlogComponent},
      {path: 'futureoftelco', component: FutureOfTelcoComponent},
      {path: 'pygmalios-for-frontline-teams', component: PygmaliosXComponent},
      {path: 'pygmalios-x', redirectTo: 'pygmalios-for-frontline-teams'},
      {path: 'privacy-policy', component: PrivacyPolicyComponent},
      {path: 'virtual-queue', component: VirtualQueueComponent},
      {path: 'products', component: ProductsComponent},
      {path: 'getdemo', component: DemoFormComponent},
      {path: 'contact', redirectTo: 'getdemo'},
      {path: '**', component: MainPageComponent}
    ]
  },
  {path: '**', component: LanguageComponent}
];

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: "enabled",
  anchorScrolling: "enabled",
  scrollOffset: [0, 130]
};

@NgModule({
  declarations: [
    AppComponent,
    NavigateComponent,
    SubscribeComponent,
    TestimonialsComponent,
    TryDemoComponent,
    LinksComponent,
    FooterComponent,
    MainPageComponent,
    ProductsComponent,
    InStoreComponent,
    CompaniesComponent,
    IndustriesComponent,
    ExpertiseComponent,
    ProductSchemaComponent,
    ProductsTopComponent,
    PygOfferingComponent,
    GetInfoComponent,
    GetDemoComponent,
    ProductItemComponent,
    ProductDescriptionComponent,
    DemoFormComponent,
    MenuComponent,
    PrivacyPolicyComponent,
    BlogComponent,
    ArticleComponent,
    BlogItemComponent,
    NgVarDirective,
    VideoEmbedComponent,
    FutureOfTelcoComponent,
    ShareButtonsComponent,
    PopupSubscribeComponent,
    PygmaliosXComponent,
    IntroSectionComponent,
    AiAssistantComponent,
    PygXCardComponent,
    BenefitsComponent,
    FreeTrialComponent,
    SuccesDialogComponent,
    FrontlineTeamsComponent,
    VirtualQueueComponent,
    VqIntroComponent,
    VgSolveProblemsComponent,
    VqAdvantagesComponent,
    VqBenefitsComponent,
    VqBannerComponent,
    LanguageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ShareButtonModule,
    ShareButtonsModule,
    ShareIconsModule,
    SwiperModule,
    ClickOutsideModule,
    AnimateOnScrollModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    RouterModule.forRoot(appRoutes, routerOptions)
  ],
  providers: [ BlogService, Meta, MetaService, PygmaliosXService, FreeTrialService ],
  bootstrap: [AppComponent]
})
export class AppModule { }
