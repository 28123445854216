import { Injectable } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { Router } from "@angular/router";

@Injectable()
export class MetaService{

  constructor(
    private titleService: Title,
    private meta: Meta,
    private router: Router
  ) { }

  setDefaultMeta(){
    if (this.router.url.split('/')[1] === 'it') {
      this.titleService.setTitle('Pygmalions | La vostra piattaforma per i migliori negozi via i data in-store');
      this.meta.updateTag({ property: 'og:description', content: 'La vostra piattaforma per i migliori negozi via i data in-store' });
      this.meta.updateTag({ name: 'description', content: 'La vostra piattaforma per i migliori negozi via i data in-store' });
    } else {
      this.titleService.setTitle('Pygmalios | Your go-to platform for better stores through in-store data');
      this.meta.updateTag({ property: 'og:description', content: 'Your go-to platform for better stores through in-store data' });
      this.meta.updateTag({ name: 'description', content: 'Your go-to platform for better stores through in-store data' });
    }

    this.meta.updateTag({ property: 'og:title', content: 'Pygmalios' });
    this.meta.updateTag({ property: 'og:url', content: 'https://pygmalios.com/' });
    this.meta.updateTag({ property: 'og:type', content: 'website' });
    this.meta.updateTag({ property: 'og:image', content: 'https://pygmalios.com/assets/img/Shared/thumbnail.jpg' });
  }

  setMeta(title:string, description: string, image:string, url:string){
    this.titleService.setTitle(title);
    this.meta.updateTag({ property: 'og:title', content: title });
    this.meta.updateTag({ property: 'og:description', content: description });
    this.meta.updateTag({ name: 'description', content: description });
    this.meta.updateTag({ property: 'og:url', content: `https://pygmalios.com/${url}` });
    this.meta.updateTag({ property: 'og:image', content: image });
  }
}
