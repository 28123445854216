import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';
import { PygmaliosXService } from '../pygmalios-x/pygmalios-x.service';
import { VQcards } from './vq-cards';

@Component({
  selector: 'app-virtual-queue',
  templateUrl: './virtual-queue.component.html',
  styleUrls: ['./virtual-queue.component.scss']
})
export class VirtualQueueComponent implements OnInit {

  VQcards = VQcards;
  trialOpen = false;

  constructor(private pxService: PygmaliosXService, private metaService: MetaService) {
    this.pxService.trialOpen.subscribe(x => {
      this.trialOpen = x;
    });
  }

  ngOnInit(): void {
    this.metaService.setMeta(
      'Virtual Queue Management for the Telco Retail',
      'An innovative cloud-based Virtual Queue Management System tailored for the Telco industry.',
      'assets/img/VirtualQueue/vqm_meta.png',
      'virtual-queue'
    )
  }

  ngOnDestroy() {
    this.metaService.setDefaultMeta();
  }
}
