<div class="pyg-dialog-bg" *ngIf="successDialogIsOpen">
  <div class="pyg-succes right-0 left-0 mx-auto"  (clickOutside)="closeSuccessDialog()">
    <div class="pyg-content">
      <img src="../../../assets/img/Shared/check-circle-purple.svg" alt="check">
      <h1 class="pt-6">{{ 'success.thank_you' | translate }}</h1>
      <p class="pt-8">
        {{ 'success.request_success' | translate }}
      </p>
      <p class="pt-3.5">
        {{ 'success.give_call' | translate }}
        <b>+421 948 945 035</b>.
      </p>
    </div>
  </div>
</div>
