import { Component, OnInit } from '@angular/core';
import { PygmaliosXService } from '../../pygmalios-x/pygmalios-x.service';

@Component({
  selector: 'app-vq-solve-problems',
  templateUrl: './vq-solve-problems.component.html',
  styleUrls: ['./vq-solve-problems.component.scss']
})
export class VgSolveProblemsComponent implements OnInit {

  constructor(private pxService: PygmaliosXService) { }

  ngOnInit(): void {
  }

  onOpenDialog() {
    this.pxService.trialOpen.next(true);
  }
}
