import { Component, OnInit } from '@angular/core';
import { MetaService } from 'src/app/services/meta.service';
import { cards } from './x-cards';
import { PygmaliosXService } from './pygmalios-x.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-pygmalios-x',
  templateUrl: './pygmalios-x.component.html',
  styleUrls: ['./pygmalios-x.component.scss']
})
export class PygmaliosXComponent implements OnInit {
  cards = cards;
  trialOpen = false;

  constructor(
    private pxService: PygmaliosXService,
    private metaService: MetaService,
    private router: Router
  ) {
    this.pxService.trialOpen.subscribe(x => {
      this.trialOpen = x;
    });

    this.router.events.subscribe((event) => {
      if(event instanceof NavigationEnd ) {
          this.langSwitch(event.url.split('/')[1]);
      }
    });
  }

  ngOnInit(): void {
    this.langSwitch(this.router.url.split('/')[1]);
  }

  langSwitch(lang: string) {
    switch(lang) {
      case 'it': this.it();
        break;
      default: this.en();
        break;
    }
  }

  en() {
    this.metaService.setMeta(
      'Pygmalios for Frontline Teams',
      'A unique connection between in-store analytics and wearables which creates the long-overdue product and delivers it right to your frontline employees’ hands.',
      'assets/img/PygmaliosX/pyg-x-meta.png',
      'pygmalios-for-frontline-teams'
    );
  }

  it () {
    this.metaService.setMeta(
      'Pygmalios per i team in prima linea',
      'Un´unica connessione fra analisi in-store e dispositivi indossabili che crea prodotto atteso da tempo e lo consegna direttamente nelle mani dei vostri dipendenti in prima linea.',
      'assets/img/PygmaliosX/pyg-x-meta.png',
      'pygmalios-for-frontline-teams'
    );
  }

  ngOnDestroy() {
    this.metaService.setDefaultMeta();
  }
}
